.Section1Card {
  max-width: 506px;
  display: flex;
  align-items: center;
  gap: 32px;
}

.Section1Card-right-title {
  color: #543a93;
  font-size: 25px;
  font-weight: 600;
  line-height: 38px;
  letter-spacing: 0em;
  text-align: left;
}

.Section1Card-right-description {
  margin-top: 16px;
  color: #5b5b5b;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
}

/* //////Mobile///// */
@media only screen and (max-width: 600px) {
  .Section1Card {
    flex-wrap: wrap;
    justify-content: space-evenly;
  }
}
/* ////////////// Tablet Design ////////////// */

@media only screen and (max-width: 768px) {
  .Section1Card {
    max-width: 310px;
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
  .Section1Card-left > img {
    width: 64px;
  }
  .Section1Card-right-title {
    font-size: 18px;
    font-weight: 600;
    line-height: 27px;
    letter-spacing: 0em;
    text-align: center;
  }
  .Section1Card-right-description {
    text-align: center;
  }
}

/* ////////////// Modile Design ////////////// */

@media only screen and (max-width: 426px) {
}
