.HoverCardContainer {
  position: relative;
  height: 420px;
  border-radius: 8px;
}

.HoverCardImage {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: 0.5s ease;
  backface-visibility: hidden;
}

.HoverCardTitle {
  position: absolute;
  bottom: 27px;
  left: 24px;
  opacity: 1;
  /* top: 85%; */
  /* width: 100%; */
  /* height: 100%; */
  /* display: flex; */
  /* flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start; */
  /* transition: 0.5s ease; */
  /* transform: translate(-50%, -50%); */
  /* -ms-transform: translate(-50%, -50%); */
}

.HoverCardTitle > div {
  color: white;
  font-size: 25px;
  font-weight: 600;
  line-height: 38px;
  letter-spacing: 0em;
  text-align: left;
}

.HoverCardMiddle {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: 0.5s ease;
  opacity: 0;
  text-align: center;
  /* transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%); */
}

/* .HoverCardContainer:hover .HoverCardImage {
  opacity: 0.3;
} */

.HoverCardContainer:hover .HoverCardTitle {
  opacity: 0;
}
.HoverCardContainer:hover .HoverCardMiddle {
  opacity: 1;
}

.HoverCardMiddleText {
  width: 100%;
  height: 100%;
  background-color: #543a9380;
  border-radius: 8px;
  color: white;
  font-size: 16px;
  text-align: left;
  padding: 60px 24px 0 24px;
}

.HoverCardMiddleText > h3 {
  font-size: 18px;
  font-weight: 600;
  line-height: 27px;
  letter-spacing: 0em;
  text-align: left;
  margin: 22px 0 0 0;
}

.HoverCardMiddleText > p {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  margin: 16px 0 0 0;
}

.HoverCardMiddleText > div {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 156px;
  height: 48px;
  margin-top: 16px;
  border: 1px solid white;
  border-radius: 8px;
}
.HoverCardMiddleText > div > a {
  color: white;
  text-decoration: none;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0em;
}
/* ////////////// for card width ////////////// */
@media only screen and (max-width: 1200px) {
  .HoverCardTitle > div {
    font-size: 22px;
  }

  .HoverCardMiddleText {
    padding: 45px 26px 0 26px;
  }

  .HoverCardMiddleText > h3 {
    margin-top: 16px;
  }

  .HoverCardMiddleText > p {
    margin-top: 8px;
  }

  .HoverCardMiddleText > div {
    margin-top: 24px;
    width: 195.65px;
    height: 54px;
  }
  .HoverCardMiddleText > div > a {
    font-size: 18px;
    font-weight: 500;
    line-height: 27px;
  }
}

/* ////////////// Tablet Design ////////////// */
@media only screen and (max-width: 1122px) {
  .HoverCardContainer {
    width: 348px;
    height: 400px;
  }
}
/* ////////////// Mobile Design ////////////// */
@media only screen and (max-width: 426px) {
}
