.CustomerOpinionSwiperContainer {
  font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
  font-size: 14px;
  color: #000;
  margin: 0;
  padding: 0;
}

.CustomerOpinionSwiper {
  width: 100%;
  min-height: 570px;
}

.CustomerOpinionSwiperSlide {
  width: 837px;
  text-align: center;
  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
}

/* ////////////// Tablet Design ////////////// */

@media only screen and (max-width: 768px) {
  .CustomerOpinionSwiper {
    min-height: 805px;
  }

  .CustomerOpinionSwiperSlide {
    width: 343px;
  }
}

/* ////////////// Modile Design ////////////// */

@media only screen and (max-width: 426px) {
}
