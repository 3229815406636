.StepsGraph {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.StepsGraph-top {
  /* width: 213px; */
  color: #543a93;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.StepsGraph-top-1 {
  height: 1px;
  background-color: #543a93;
  width: 63px;
}

.StepsGraph-top-2 {
  margin-left: 3px;
  position: relative;
  text-align: center;
  display: flex;
  align-items: center;
}

.StepsGraph-top-3 {
  position: absolute;
  /* bottom: 8px; */
  /* top: 33%; */
  left: 10px;
  font-size: 18px;
  font-weight: 600;
  line-height: 27px;
  letter-spacing: 0em;
  color: #543a93;
}

/*               bottom               */
.StepsGraph-bottom {
  margin-top: 48px;
  width: 100%;
}
.StepsGraph-bottom-numbers {
  position: relative;
  /* max-width: 981px; */
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 91.5px;
}

.StepsGraph-bottom-numbers-line {
  position: absolute;
  z-index: -1;
  width: 100%;
  border-bottom: 1px solid #c8c8c8;
}

.StepsGraph-bottom-numbers-circule {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  border: 2px solid #c8c8c8;
  border-radius: 100px;
  width: 64px;
  height: 64px;
  z-index: 3;
}

/*           steps           */

.StepsGraph-bottom-steps {
  margin-top: 48px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: row;
}
.StepsGraph-bottom-steps-card {
  max-width: 242px;
}
.StepsGraph-bottom-steps-card-title {
  color: black;
  text-align: center;
  font-size: 18px;
  font-weight: 600;
  line-height: 27px;
  letter-spacing: 0em;
}
.StepsGraph-bottom-steps-card-description {
  margin-top: 16px;
  color: #5b5b5b;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: center;
}

/* ////////////// Tablet Design ////////////// */
@media only screen and (max-width: 768px) {
  .StepsGraph-top-2-img {
    width: 147px;
  }
  .StepsGraph-bottom-steps {
    margin-top: 24px;
    display: flex;
    gap: 24px;
    flex-wrap: wrap;
    justify-content: space-evenly;
  }
  .StepsGraph-bottom-steps-card {
    max-width: 300px;
  }
}

/* ////////////// Mobile Design ////////////// */
@media only screen and (max-width: 426px) {
  .WorkingSteps {
    padding: 0 16px;
  }
  .StepsGraph-bottom-steps {
    gap: 32px;
  }
  .StepsGraph-bottom-numbers {
    margin: 0 29px;
  }
  .StepsGraph-bottom-numbers-circule {
    width: 35.5px;
    height: 35.5px;
  }
  .StepsGraph-bottom-steps-card {
    max-width: 249px;
  }
}
