.HeaderSliderImg_1 {
  background: linear-gradient(
      90deg,
      rgba(95, 54, 146, 0.3) 0%,
      rgba(84, 58, 147, 0.189) 100%
    ),
    url("../../../images/photo/home/hero section/HeaderImage1.png");
  background-size: cover;
}
.HeaderSliderImg_2 {
  background: linear-gradient(
      90deg,
      rgba(95, 54, 146, 0.3) 0%,
      rgba(84, 58, 147, 0.189) 100%
    ),
    url("../../../images/photo/home/hero section/bigData2.jpg");
  background-size: cover;
  background-position-x: left;
  background-position-y: top;
}
.HeaderSliderImg_3 {
  background: linear-gradient(
      90deg,
      rgba(95, 54, 146, 0.3) 0%,
      rgba(84, 58, 147, 0.189) 100%
    ),
    url("../../../images/photo/home/hero section/DigitalAutomation4.PNG");
  background-size: cover;
  background-position-x: right;
  background-position-y: bottom;
}
.HeaderSliderImg_4 {
  background: linear-gradient(
      90deg,
      rgba(95, 54, 146, 0.3) 0%,
      rgba(84, 58, 147, 0.189) 100%
    ),
    url("../../../images/photo/home/hero section/IT_Management.svg");
  background-size: cover;
  background-position-x: right;
  background-position-y: bottom;
}

/* .HeaderSwiper {
  --swiper-theme-color: #f9d34f;
} */

.HeaderSlider {
  width: 100%;
  height: 720px;
  object-fit: cover;
  background-repeat: no-repeat;
}
/* ////////////// labtop 1440 Design ////////////// */

@media only screen and (max-width: 1440px) {
  .HeaderSlider {
    height: 782px;
  }
}

/* ////////////// labtop 1024 Design ////////////// */

@media only screen and (max-width: 1024px) {
  .HeaderSlider {
    height: 624px;
  }
}

/* ////////////// Tablet Design ////////////// */

@media only screen and (max-width: 768px) {
  .HeaderSlider {
    height: 625px;
  }
  .HeaderSliderImg_2 {
    background-position-y: -43px;
    background-position-x: -3px;
    background-size: 900px;
  }
  .HeaderSliderImg_3 {
    background-position: center;
  }
}

/* ////////////// Modile L Design ////////////// */

@media only screen and (max-width: 426px) {
  .HeaderSlider {
    height: 812px;
  }
  .HeaderSliderImg_2 {
    background-size: cover;
    background-position: unset;
  }
}
