.ClientsHeader {
  width: 100%;
  background-image: url("ClientsHeaderImg.svg");
  background-repeat: no-repeat;
  background-size: cover;
  object-fit: cover;
  height: 355px;
}

.ClientsHeaderText {
  padding-top: 215px;
  padding-left: 98px;
  text-align: left;
}

.ClientsHeaderText > h1 {
  color: rgba(255, 255, 255, 1);
  font-size: 40px;
  font-weight: 600;
  line-height: 52px;
  letter-spacing: 0em;
  margin: 0;
}

.ClientsHeaderPath {
  display: flex;
  flex-direction: row;
  color: rgba(200, 200, 200, 1);
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  margin-top: 16px;
}
.ClientsHeaderPath-last {
  color: rgba(247, 198, 29, 1);
  margin-left: 5px;
}
/* ////////////// Tablet Design ////////////// */

@media only screen and (max-width: 768px) {
  .ClientsHeader {
    height: 208px;
  }
  .ClientsHeaderText {
    padding-top: 125px;
    padding-left: 24px;
  }
  .ClientsHeaderText > h1 {
    font-size: 18px;
    font-weight: 600;
    line-height: 27px;
  }
  .ClientsHeaderPath {
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    margin-top: 8px;
  }
}

/* ////////////// Modile Design ////////////// */

@media only screen and (max-width: 426px) {
  .ClientsHeader {
    height: 181px;
  }
  .ClientsHeaderText {
    padding-top: 94px;
    padding-left: 16px;
  }
}
