.HeaderSlideContentContainer {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  padding: 183px 0 0 98px;
  width: 100%;
  height: 100%;
  position: relative;
}

.welcomeSlide {
  padding-top: 337px;
}

.HeaderSlideContent-left {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.HeaderSlideTextBox {
  max-width: 727px;
  text-align: left;
  color: white;
}

.HeaderSlideTitle {
  font-size: 18px;
  font-weight: 600;
  line-height: 27px;
  letter-spacing: 0em;
  /* animation: slideInDown 2.5s ease 0.1s forwards; */
}

.slideInDown-Animation {
  animation: slideInDown 2s ease 10ms forwards;
}

.fadeInLeft-Animation {
  animation: fadeInLeft 1s ease 10ms forwards;
}

.fadeIn-Animation {
  animation: fadeIn 2.5s ease 10ms forwards;
}

/* @keyframes slideInDown {
  0% {
    transform: translateY(-100%);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
} */

.HeaderSlideSubTitle {
  color: #fff;
  font-size: 40px;
  font-weight: 800;
  line-height: 52px;
  letter-spacing: 0em;
  margin-top: 16px;
}
.HeaderSlideDescription {
  max-width: 508px;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  margin-top: 24px;
}

.HeaderSlideBtnBox {
  margin-top: 64px;
  display: flex;
  flex-direction: row;
  gap: 16px;
}
.HeaderSlideContact {
  background: linear-gradient(180deg, #ffffff 0%, #e6d3ff 100%);
  color: #5f4992;
  border: none;
  border-radius: 8px;
  font-size: 18px;
  font-weight: 600;
  line-height: 27px;
  letter-spacing: 0em;
  text-align: center;
  padding: 16px 48px;
}

.HeaderSlideContact:hover {
  background: linear-gradient(180deg, #ede9f4 0%, #e6d3ff 100%);
}

.HeaderSlideDiscover {
  background: transparent;
  color: #ffffff;
  border: 1px solid;
  border-radius: 8px;
  padding: 16px 27px;
  font-size: 18px;
  font-weight: 500;
  line-height: 27px;
  letter-spacing: 0em;
  text-align: center;
}

.HeaderSlideDiscover:hover {
  color: #f7c61d;
}

.HeaderSlideContent-right {
  position: absolute;
  right: 25px;
  bottom: 0;
  width: 33%;
}

.HeaderSlideContent-right > img {
  width: 100%;
  opacity: 0.8;
}

/* ////////////// Tablet Design ////////////// */

@media only screen and (max-width: 1024px) {
  .HeaderSlideSubTitle {
    max-width: 604px;
    font-size: 32px;
    line-height: 48px;
  }

  .HeaderSlideDescription {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
  }

  .HeaderSlideContact {
    padding: 12px 25px;
    font-size: 16px;
    line-height: 24px;
  }
  .HeaderSlideDiscover {
    padding: 12px 11px;
    font-size: 16px;
    line-height: 24px;
  }
}

/* ////////////// Tablet Design ////////////// */

@media only screen and (max-width: 768px) {
  .HeaderSlideContentContainer {
    padding: 137px 0 0 16px;
  }
}

/* ////////////// Modile Design ////////////// */

@media only screen and (max-width: 426px) {
  .HeaderSlideContentContainer {
    padding: 149px 16px 0 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .HeaderSlideTextBox {
    text-align: center;
  }

  .HeaderSlideTitle {
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
  }
  .HeaderSlideSubTitle {
    font-size: 25px;
    line-height: 38px;
  }
  .HeaderSlideBtnBox {
    margin: auto;
    margin-top: 48px;
  }
}
