.ClientsSection1 {
  padding: 32px;
  margin: auto;
  display: grid;
  grid-template-columns: auto auto auto auto;
  justify-content: space-evenly;
  align-items: center;
  justify-items: center;
}

.ClientsSection1-logo {
  height: 100%;
  padding: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ClientsSection1-logo:hover {
  background-color: rgba(180, 180, 180, 0.204);
  border-radius: 8px;
}

.ClientsSection1-logo > img {
  max-width: 170px;
  max-height: 130px;
}
/* ////////////// Tablet Design ////////////// */

@media only screen and (max-width: 768px) {
  .ClientsSection1-logo > img {
    max-width: 140px;
    max-height: 113px;
  }
}

/* ////////////// Modile Design ////////////// */

@media only screen and (max-width: 426px) {
  .ClientsSection1 {
    padding: 64px 16px 16px 16px;
    grid-template-columns: auto;
    gap: 20px;
  }

  .ClientsSection1-logo > img {
    max-width: 205px;
    max-height: 141px;
  }
}
