.DataAndAIServicesSection1 {
  padding: 60px 97px;
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  flex-direction: row;
  gap: 48px;
}

.DataAndAIServicesSection1-left {
  max-width: 620px;
  text-align: left;
}

.DataAndAIServicesSection1-left-1 {
  color: #242424;
  font-size: 25px;
  font-weight: 600;
  line-height: 38px;
  letter-spacing: 0em;
}
.DataAndAIServicesSection1-left-2 {
  color: #5b5b5b;
  font-size: 15px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  max-width: 572px;
  margin-top: 32px;
}
.DataAndAIServicesSection1-left-3 {
  color: #494949;
  font-size: 15px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  margin-top: 32px;
}

.DataAndAIServicesSection1-right {
  position: relative;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #5f4992;
  opacity: 0.2;
}

.DataAndAIServicesSection1-right > img {
  max-width: 576px;
  height: 438px;
  object-fit: cover;
  border-radius: 6px;
}
/* ////////////// Tablet Design ////////////// */

@media only screen and (max-width: 1024px) {
  .DataAndAIServicesSection1 {
    padding: 48px 24px;
    display: flex;
    flex-direction: column-reverse;
    gap: 32px;
  }
  .DataAndAIServicesSection1-right {
    width: 100%;
  }
  .DataAndAIServicesSection1-right > img {
    width: 100%;
    max-width: none;
    height: 250px;
    object-fit: cover;
  }
  .DataAndAIServicesSection1-left-2 {
    margin-top: 16px;
  }
  .DataAndAIServicesSection1-left-3 {
    margin-top: 24px;
  }
}

/* ////////////// Modile Design ////////////// */

@media only screen and (max-width: 426px) {
  .DataAndAIServicesSection1 {
    padding: 32px 16px;
    gap: 16px;
  }
  .DataAndAIServicesSection1-right > img {
    height: 326px;
  }
}
