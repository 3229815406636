.footer {
  width: 100%;
  min-height: 532px;
  padding: 16px 98px;
  background-color: #543a93;
  margin-top: 34px;
  left: 0;
  right: 0;
  bottom: 0;
  color: #dfdfdf;
}

.footer-section-1 {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 32px 0px;
  border-bottom: 1px solid #7f68b4;
}
.footer-section-1-text {
  text-align: left;
}
.footer-section-1-text-1 {
  font-size: 32px;
  font-weight: 600;
  line-height: 48px;
  letter-spacing: 0em;
  text-align: left;
}

.footer-section-1-text-2 {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
}

.footer-section-1-btn a {
  text-decoration: none;
}
.footer-section-1-btn a div {
  width: 180px;
  height: 59px;
  border-radius: 8px;
  background: linear-gradient(180deg, #ffffff 0%, #e6d3ff 100%);
  font-size: 18px;
  font-weight: 500;
  line-height: 27px;
  letter-spacing: 0em;
  color: #5f4992;
  display: flex;
  align-items: center;
  justify-content: center;
}

.footer-section-1-btn a div:hover {
  background: linear-gradient(180deg, #ede9f4 0%, #e6d3ff 100%);
}

.footer-section-2 {
  margin: 32px 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  flex-direction: row;
  gap: 32px;
}

.footer-section-2-left {
  text-align: left;
  display: flex;
  max-width: 307px;
  flex-direction: column;
  align-items: flex-start;
}

.footer-section-2-left img {
  width: 162px;
  /* height: 22.01px; */
  margin-bottom: 32px;
}

.footer-section-2-left-text {
  margin-bottom: 32px;
  /* font-family: Inter; */
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.10000000149011612px;
  text-align: left;
}

.footer-section-2-left-input {
  display: flex;
  align-items: center;
}
.footer-section-2-left-input input {
  width: 219px;
  height: 46px;
  border: none;
  border-radius: 6px 0px 0px 6px;
  padding: 12px 16px;
  outline: none;
}

.footer-section-2-left-input button {
  width: 88px;
  height: 46px;
  border: none;
  border-radius: 0px 6px 6px 0px;
  background: #f7c61d;
  box-shadow: 0px 1px 2px 0px #1018280a;
  color: #5b5b5b;

  font-size: 15px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0px;
}
.footer-section-2-left-input button:hover {
  color: #373737;
}

.footer-section-2-right {
  max-width: 697px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.footer-grid-Container {
  display: flex;
  align-items: flex-start;
  gap: 48px;
  /* grid-template-columns: auto auto auto; */
}

.footer-grid-item {
  font-size: 30px;
  text-align: center;
}

.footer-grid-item > div {
  font-size: 18px;
  font-weight: 600;
  line-height: 27px;
  letter-spacing: 0em;
  text-align: left;
  margin-bottom: 16px;
}

.footer-grid-item li,
.footer-grid-item li > div {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  list-style: none;
  margin-bottom: 8px;
}

.footer-grid-item a {
  text-decoration: none;
  color: #dfdfdf;
  text-wrap: nowrap;
}
/* 
.footer-solution,
.footer-links {
  margin-right: 48px;
} */
.footer-Address li {
  display: flex;
  max-width: 290px;
}

.footer-solution > a:hover,
.footer-links > a:hover {
  color: #f7c61d;
}

.footer-solution li {
  text-wrap: nowrap;
}

/* ///////////////////////// */
.footer-section-3 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid #7f68b4;
  padding: 16px 0;
}
.footer-section-3-left {
  display: flex;
  align-items: center;
}

.footer-section-3-left-1 {
  color: #dad4e9;
  font-size: 18px;
  font-weight: 400;
  line-height: 27px;
  letter-spacing: 0em;
  text-align: left;
}

.footer-section-3-left-2 {
  margin-left: 36px;
}

.footer-section-3-right-btn {
  width: 38px;
  height: 38px;
  border: none;
  border-radius: 6px;
  background-color: #f7c61d;
  text-align: center;
  color: white;
}

.footer-address-Icons {
  color: white;
  width: 24px;
  height: 24px;
  margin-right: 16px;
}
.footer-Icons {
  color: white;
  width: 24px;
  height: 24px;
  margin-right: 24px;
}

/* ////////////// Tablet Design ////////////// */

@media only screen and (max-width: 768px) {
  .footer {
    padding: 16px 24px;
  }

  .footer-section-1 {
    flex-direction: column;
    align-items: flex-start;
    gap: 32px;
    padding: 0 0 16px 0;
  }

  .footer-section-1-text-1 {
    font-size: 25px;
    font-weight: 600;
    line-height: 38px;
  }

  .footer-section-1-text-2 {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    margin-top: 16px;
  }

  .footer-section-1-btn a div {
    width: 180px;
    height: 48px;

    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
  }

  .footer-section-2 {
    flex-direction: column;
    align-items: center;
  }
  .footer-section-2-left {
    max-width: 100%;
  }

  .footer-section-2-left-input {
    width: 100%;
  }

  .footer-section-2-left > img {
    width: 91.13px;
    margin-bottom: 0;
  }

  .footer-section-2-left-text {
    margin: 16px 0 0 0;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
  }

  .footer-section-2-left-input {
    margin-top: 16px;
  }
  .footer-section-2-left-input input {
    width: calc(100% - 88px);
  }

  .footer-section-2-right {
    margin-top: 16px;
    width: 100%;
  }

  .footer-grid-Container {
    width: 100%;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 16px;
  }
  .footer-solution {
    max-width: 129px;
  }
  .footer-solution,
  .footer-links {
    margin: 0;
  }
  .footer-solution li {
    text-wrap: wrap;
  }

  .footer-links {
    min-width: 86px;
  }

  .footer-section-3 {
    padding: 16px 0 0 0;
    margin-top: 16px;
  }

  .footer-section-3-left {
    align-items: flex-start;
    flex-direction: column-reverse;
  }

  .footer-section-3-left-2 {
    margin: 0 0 32px 0;
  }

  .footer-section-3-right {
    display: none;
  }
}

/* ////////////// Modile Design ////////////// */

@media only screen and (max-width: 426px) {
  .footer-section-1 {
    gap: 32px;
  }
  .footer-section-1-text-1 {
    font-size: 25px;
    line-height: 38px;
  }

  .footer-section-1-text-2 {
    margin-top: 16px;
  }

  .footer-section-2 {
    display: flex;
    gap: 16px;
  }

  .footer-section-2-right {
    margin-top: 0;
  }

  .footer-grid-Container {
    flex-wrap: wrap;
    justify-content: space-around;
    gap: 16px;
  }

  .footer-solution,
  .footer-links {
    margin: 0;
  }

  .footer-section-3 {
    margin-top: 0;
  }

  .footer-section-3-left-1 {
    display: none;
  }

  .footer-section-3-left-2 {
    margin: 0;
  }
}
