.Section3 {
  background-color: #f2edff;
  margin-top: 64px;
  padding: 48px 95.5px;
  text-align: center;
}

.Section3-title {
  font-size: 40px;
  font-weight: 600;
  line-height: 52px;
  letter-spacing: 0em;
  color: #242424;
}

.Section3-subTitle {
  color: #5b5b5b;
  margin-top: 16px;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
}

.Section3-bottom {
  width: 100%;
  margin-top: 48px;
}
.Section3-bottom > div {
  max-width: 1084px;
  margin: auto;
  display: flex;
  flex-wrap: wrap;
  /* justify-content: center; */
  gap: 32px;
}

.Section3-bottom-card {
  width: 340px;
  padding: 32px;
  border: 1px solid #ede9f4;
  border-radius: 8px;
  text-align: left;
}

.Section3-bottom-card-title {
  margin-top: 8px;
  color: #242424;
  font-size: 18px;
  font-weight: 600;
  line-height: 27px;
  letter-spacing: 0em;
}

.Section3-bottom-card-definition {
  margin-top: 16px;
  color: #5b5b5b;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
}
/* ////////////// Tablet Design ////////////// */

@media only screen and (max-width: 768px) {
  .Section3 {
    margin-top: 48px;
    padding: 32px 24px;
  }
  .Section3-title {
    font-size: 25px;
    font-weight: 600;
    line-height: 38px;
    letter-spacing: 0em;
    text-align: center;
  }
  .Section3-subTitle {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: center;
  }
  .Section3-bottom {
    margin-top: 32px;
  }
}

/* ////////////// Modile Design ////////////// */

@media only screen and (max-width: 426px) {
}
