.ContactUsSection3 {
  margin-top: 64px;
  padding: 0 98px;
  text-align: left;
}

.ContactUsSection3-title {
  color: #242424;
  font-size: 40px;
  font-weight: 600;
  line-height: 52px;
  letter-spacing: 0em;
}

.ContactUsSection3-mapContainer {
  margin-top: 40px;
}

.ContactUsSection3-map {
  border: 0;
  width: 100%;
  max-width: 1440px;
  height: 400px;
}
/* ////////////// Tablet Design ////////////// */

@media only screen and (max-width: 768px) {
  .ContactUsSection3 {
    margin-top: 48px;
    padding: 0 24px;
  }
  .ContactUsSection3-title {
    font-size: 25px;
    font-weight: 600;
    line-height: 38px;
  }
  .ContactUsSection3-mapContainer {
    margin-top: 32px;
  }
}

/* ////////////// Modile Design ////////////// */

@media only screen and (max-width: 426px) {
  .ContactUsSection3 {
    margin-top: 32px;
    padding: 0 16px;
  }
}
