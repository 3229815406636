.OurTechnologiesSection1 {
  padding: 64px;
  margin: auto;
  display: grid;
  grid-template-columns: auto auto auto;
  justify-items: center;
  align-items: center;
  gap: 32px;
  max-width: 70%;
}

.OurTechnologiesSection1-logo {
  padding: 16px;
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  /* min-height: 150px; */
}
.OurTechnologiesSection1-logo:hover {
  background-color: #e0e0e030;
  border-radius: 8px;
}
.OurTechnologiesSection1-logo > img {
  max-width: 140px;
  max-height: 80px;
}

.fadeInBottom {
  animation-name: fadeInBottom;
  animation-duration: 1s;
  animation-fill-mode: both;
}
@keyframes fadeInBottom {
  from {
    opacity: 0;
    transform: translateY(100%);
  }
  to {
    opacity: 1;
  }
}
/* ////////////// Tablet Design ////////////// */

@media only screen and (max-width: 768px) {
  .OurTechnologiesSection1 {
    max-width: none;
  }
}

/* ////////////// Modile Design ////////////// */

@media only screen and (max-width: 426px) {
  .OurTechnologiesSection1 {
    grid-template-columns: auto;
    gap: 64px;
  }
}
