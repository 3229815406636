.Section1 {
  background-image: url("mapImage.svg");
  background-position-x: center;
  background-repeat: no-repeat;

  position: relative;
  margin-top: 73px;
  padding: 0 95.5px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.Section1-top {
  max-width: 978px;
  text-align: center;
}

.Section1-top-1 {
  color: #373737;
  font-size: 40px;
  font-weight: 600;
  line-height: 52px;
  letter-spacing: 0em;
  text-align: center;
}
.Section1-top-1 > span {
  background: linear-gradient(0deg, #7f68b4, #7f68b4);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.Section1-top-2,
.Section1-top-3 {
  margin-top: 32px;
  color: #5b5b5b;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: center;
}

.Section1-top-2 > span {
  font-weight: bold;
}

.Section1-top-3 {
  font-weight: bold;
}

/* //////////// Bottom //////////// */
.Section1-bottom {
  margin-top: 48px;
  max-width: 1060px;
}
.Section1-bottom-1 {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-evenly;
  gap: 48px;
}

.Section1-bottom-2 {
  margin-top: 48px;
}

.Section1-bottom-2-title {
  color: #543a93;
  font-size: 32px;
  font-weight: 600;
  line-height: 48px;
  letter-spacing: 0em;
  text-align: left;
}

.Section1-bottom-2-definitions {
  margin-top: 32px;
  display: flex;
  flex-direction: column;
  gap: 32px;
}

.Section1-bottom-2-definitions-partition {
  display: flex;
  flex-direction: row;
  gap: 113px;
}

.Section1-bottom-2-definitions-card {
  max-width: 452px;
  color: #494949;
  font-size: 18px;
  font-weight: 400;
  line-height: 27px;
  letter-spacing: 0em;
  text-align: left;
}

.Section1-bottom-2-definitions-card > span {
  font-weight: bold;
}

/* //////Mobile///// */
@media only screen and (max-width: 600px) {
  .Section1-bottom-2-definitions-partition {
    display: flex;
    flex-direction: column;
    gap: 113px;
  }
}
/* ////////////// Tablet Design ////////////// */

@media only screen and (max-width: 768px) {
  .Section1 {
    margin-top: 48px;
    padding: 0 24px;
  }
  .Section1-top-1 {
    font-size: 25px;
    font-weight: 600;
    line-height: 38px;
    letter-spacing: 0em;
    max-width: 320px;
    margin: auto;
  }
  .Section1-top-2,
  .Section1-top-3 {
    margin-top: 16px;
  }
  .Section1-bottom {
    margin-top: 40px;
  }
  .Section1-bottom-1 {
    display: flex;
    flex-wrap: nowrap;
    gap: 64px;
  }
  .Section1-bottom-2-title {
    font-size: 25px;
    font-weight: 600;
    line-height: 38px;
    letter-spacing: 0em;
  }
  .Section1-bottom-2-definitions,
  .Section1-bottom-2-definitions-partition {
    gap: 24px;
  }
  .Section1-bottom-2-definitions-card {
    max-width: 348px;
  }
}

/* ////////////// Modile Design ////////////// */

@media only screen and (max-width: 426px) {
  .Section1 {
    margin-top: 32px;
  }
  .Section1-bottom-1 {
    flex-direction: column;
  }
  .Section1-bottom-2-title {
    font-size: 32px;
    font-weight: 600;
    line-height: 48px;
    text-align: center;
  }
}
