.ContactUsSection1 {
  margin-top: 60px;
  padding: 0 98px;
  display: flex;
  flex-wrap: wrap;
  gap: 137px;
}
.ContactUsSection1-left {
  text-align: left;
  max-width: 528px;
}
.ContactUsSection1-left-1 {
  color: rgba(36, 36, 36, 1);
  font-size: 32px;
  font-weight: 600;
  line-height: 48px;
  letter-spacing: 0em;
  text-align: left;
}

.ContactUsSection1-left-2 {
  color: rgba(91, 91, 91, 1);
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  margin-top: 16px;
}

.ContactUsSection1-left-3 {
  margin-top: 27px;
  display: grid;
  gap: 16px;
}

.ContactUsSection1-left-3-top {
  display: flex;
  align-items: flex-start;
  gap: 16px;
}

.ContactUsSection1-left-3-mid {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.ContactUsSection1-left-3-label {
  color: rgba(73, 73, 73, 1);
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
  width: 100%;
  margin-bottom: 4px;
}

.ContactUsSection1-left-3-PhoneInput {
  border: 1.5px solid #a4a4a4;
  border-radius: 4px;
  padding-left: 16px;
}

.ContactUsSection1-left-3-PhoneInput > input {
  height: 49px;
  border: 0;
  padding-left: 8px;
}

.ContactUsSection1-left-3-input-1 :active {
  border: 1.5px solid rgba(164, 164, 164, 1);
}

.ContactUsSection1-left-3-input-1,
.ContactUsSection1-left-3-input-2 {
  width: 256px;
  height: 49px;
  padding: 14px 16px 14px 16px;
  border: 1.5px solid rgba(164, 164, 164, 1);
  border-radius: 4px;
  gap: 8px;
  color: black;
}

.ContactUsSection1-left-3-input-1,
.ContactUsSection1-left-3-input-2,
.ContactUsSection1-left-3-textarea,
.PhoneInputInput:focus {
  outline: none;
}

.ContactUsSection1-left-3-input-1::placeholder,
.ContactUsSection1-left-3-input-2::placeholder,
.ContactUsSection1-left-3-textarea::placeholder,
.PhoneInputInput ::placeholder {
  color: rgba(164, 164, 164, 1);
}

.ContactUsSection1-left-3-input-2 {
  width: 100%;
}

.ContactUsSection1-left-3-textarea {
  width: 100%;
  /* height: 100px; */
  border-radius: 4px;
  border: 1.5px solid rgba(164, 164, 164, 1);
  padding: 5px;
}

.ContactUsSection1-left-3-textarea::placeholder {
  color: rgba(164, 164, 164, 1);
  position: absolute;
  top: 4px;
  left: 8px;
}

.error-span {
  color: red;
  font-size: small;
}

.ContactUsSection1-left-4 {
  margin-top: 32px;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  gap: 32px;
}

.ContactUsSection1-left-4 > div {
  display: flex;
  flex-direction: row;
  gap: 8px;
}

.ContactUsSection1-left-4 > div > input {
  width: 20.38px;
  height: 20px;
  cursor: pointer;
}

.ContactUsSection1-left-4 > div > div {
  color: rgba(55, 55, 55, 1);
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
}

.ContactUsSection1-left-5 {
  margin-top: 32px;
}

.ContactUsSection1-left-5-btn {
  background-color: rgba(95, 73, 146, 1);
  width: 100%;
  height: 59px;
  border: 0;
  border-radius: 8px;
  color: white;
}

.ContactUsSection1-right > img {
  width: 579px;
  height: 726px;
  object-fit: cover;
  border-radius: 8px;
}
/* ////////////// Tablet Design ////////////// */

@media only screen and (max-width: 1024px) {
  .ContactUsSection1 {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    gap: 32px;
    margin-top: 48px;
    padding: 0 24px;
  }
  .ContactUsSection1-right {
    width: 100%;
  }
  .ContactUsSection1-right > img {
    width: 100%;
    height: 367px;
  }
  .ContactUsSection1-left {
    max-width: unset;
    width: 100%;
    text-align: center;
  }
  .ContactUsSection1-left-1 {
    font-size: 25px;
    font-weight: 600;
    line-height: 38px;
    letter-spacing: 0em;
    text-align: center;
  }
  .ContactUsSection1-left-2 {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: center;
  }
  .ContactUsSection1-left-3 {
    margin-top: 32px;
    gap: 20px;
  }

  .ContactUsSection1-left-3-top {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
  }

  .ContactUsSection1-left-3-input-1,
  .ContactUsSection1-left-3-input-2,
  .ContactUsSection1-left-3-top-FName,
  .ContactUsSection1-left-3-top-LName,
  .ContactUsSection1-left-3-email,
  .ContactUsSection1-left-3-phone {
    width: 100%;
  }
  .ContactUsSection1-left-3-mid {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
  }
  .ContactUsSection1-left-3-textarea {
    height: 74px;
  }
}

/* ////////////// Modile Design ////////////// */

@media only screen and (max-width: 426px) {
  .ContactUsSection1 {
    margin-top: 32px;
    padding: 0 16px;
  }
  .ContactUsSection1-right > img {
    height: 292px;
  }
  .ContactUsSection1-left-3-top,
  .ContactUsSection1-left-3-mid {
    flex-direction: column;
  }
  .ContactUsSection1-left-4 {
    margin-top: 24px;
  }
  .ContactUsSection1-left-5-btn {
    height: 51px;
    font-size: 18px;
    font-weight: 500;
    line-height: 27px;
  }
}
