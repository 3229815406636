.Section2Card {
  background-color: #5f4992;
  width: 393.33px;
  padding: 24px 16px;
  border-radius: 8px;
  color: #fef9e6;
  text-align: left;
}

.Section2Card-title {
  font-size: 24px;
  font-weight: 600;
  line-height: 36px;
  letter-spacing: 0em;
}

.Section2Card-Address-container {
  margin-top: 24px;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 16px;
}

.Section2Card-Address-Icon {
  width: 24px;
}

.Section2Card-address {
  font-size: 16px;
  font-weight: 300;
  line-height: 24px;
  letter-spacing: 0em;
  /* margin-left: 5px; */
  text-wrap: balance;
}

.Section2Card-contactsInfo {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
  /* gap: 60px; */
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
}

.Section2Card-SaudArab {
  flex-direction: column;
  gap: 16px;
}

.Section2Card-contactsInfo > div {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.Section2Card-contact {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
}

.Section2Card-contact-Icon {
  width: 24px;
  margin-right: 16px;
  color: #f7c61d;
}
.Section2Card-contact-Telephones {
  display: flex;
  flex-direction: column;
}
.Section2Card-contact-Telephone {
  margin: 0 5px 0 0;
}
/* ////////////// Tablet Design ////////////// */

@media only screen and (max-width: 768px) {
  .Section2Card-address {
    margin: 0;
  }
  .Section2Card-contact-Telephone {
    align-items: flex-start;
  }
  .Section2Card-contactsInfo-bottom {
    margin-top: 8px;
  }

  .Section2Card-contactsInfo {
    flex-direction: column;
    margin-top: 8px;
  }
}

/* ////////////// Modile Design ////////////// */
@media only screen and (max-width: 426px) {
}
