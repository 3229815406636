.ITManagementSection1 {
  margin-top: 60px;
  padding: 0 98px;
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  gap: 48px;
}

.ITManagementSection1-left {
  text-align: left;
  color: #5b5b5b;
}

.ITManagementSection1-left-1 {
  color: #242424;
  font-size: 25px;
  font-weight: 600;
  line-height: 38px;
  letter-spacing: 0em;
}

.ITManagementSection1-left-2 {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  margin-top: 32px;
  max-width: 572px;
}

.ITManagementSection1-left-3 {
  margin-top: 32px;
  color: #494949;
}

.ITManagementSection1-left-3 > ul {
  margin: 0;
}

.ITManagementSection1-right > img {
  width: 576px;
  border-radius: 6px;
}
/* ////////////// Tablet Design ////////////// */

@media only screen and (max-width: 1024px) {
  .ITManagementSection1 {
    padding: 48px 24px;
    margin: 0;
    flex-direction: column-reverse;
    gap: 24px;
  }
  .ITManagementSection1-right {
    width: 100%;
  }
  .ITManagementSection1-right > img {
    width: 100%;
    height: 250px;
    object-fit: cover;
  }
  .ITManagementSection1-left-2 {
    margin-top: 16px;
  }
}

/* ////////////// Modile Design ////////////// */

@media only screen and (max-width: 426px) {
  .ITManagementSection1 {
    padding: 32px 16px;
  }
  .ITManagementSection1-left-3 {
    margin-top: 24px;
  }
  .ITManagementSection1-left-3 > ul > li {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
  }
}
