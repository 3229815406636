.CustomerOpinionCard {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* width: 830px;*/
  min-height: 504px;
  background-color: white;
  border-radius: 20px;
}

.CustomerOpinionCard::before {
  content: "";
  position: absolute;
  height: 84px;
  width: 100%;
  background: #f2edff;
}

.CustomerOpinionCard-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* padding: 30px; */
  position: relative;
  z-index: 100;
}

.CustomerOpinionCardTob {
  background-color: transparent;
  width: 100%;
  height: 84px;
}
.CustomerOpinionCardMed {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}
.CustomerOpinionCardIconframeContainer {
  width: 144px;
  /* height: 144px; */
  background-color: #543a93;
  border-radius: 50%;
  padding: 2px;
}
.CustomerOpinionCardIconframe {
  background-color: white;
  width: 140px;
  height: 140px;
  border: 2px;
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.CustomerOpinionCardIconframe > img {
  max-width: 136px;
}

.CustomerOpinionCardBottom {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 47.5px 0 16px 0;
  padding: 0 16px;
}
.CustomerOpinionCardCompName {
  font-size: 18px;
  font-weight: 500;
  line-height: 27px;
  letter-spacing: 0em;
  text-align: left;

  color: #373737;
}
.CustomerOpinionCardImg {
  width: 100%;
  margin-top: 16px;
}
.CustomerOpinionCardImg img {
  width: 70px;
  height: 50px;
}

.CustomerOpinionCardFeedback {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: center;
  margin-top: 16px;
  color: #5b5b5b;
}
/* ////////////// Tablet Design ////////////// */

@media only screen and (max-width: 768px) {
  .CustomerOpinionCard {
    min-height: 773px;
  }

  .CustomerOpinionCardBottom {
    margin-top: 16px;
  }
}

/* ////////////// Modile Design ////////////// */

@media only screen and (max-width: 426px) {
}
