.ITManagementSection2 {
  margin-top: 69px;
  padding: 0 98px;
  text-align: left;
}

.ITManagementSection2-top {
  color: #242424;
  font-size: 25px;
  font-weight: 600;
  line-height: 38px;
  letter-spacing: 0em;
}

.ITManagementSection2-bottom {
  margin-top: 32px;
  color: #494949;
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  gap: 80px;
}

.ITManagementSection2-bottom-square {
  display: flex;
  justify-content: space-evenly;
  flex-direction: column;
  align-items: flex-start;
}

.ITManagementSection2-bottom-card {
  display: flex;
  align-items: flex-start;
  margin-bottom: 32px;
}

.ITManagementSection2-bottom-card > p {
  margin-left: 24px;
  margin-bottom: 0px;
  max-width: 434px;
}

.ITManagementSection2-bottom-card > p > span {
  font-weight: bold;
}
/* ////////////// 1024px Design ////////////// */

@media only screen and (max-width: 1024px) {
  .ITManagementSection2 {
    padding: 0 24px;
    margin: 0;
  }
  .ITManagementSection2-bottom-card > p {
    max-width: 302px;
    margin-left: 16px;
  }
}
/* ////////////// Tablet Design ////////////// */

@media only screen and (max-width: 768px) {
  .ITManagementSection2-bottom {
    display: flex;
    flex-direction: column;
    gap: 0;
  }
  .ITManagementSection2-bottom-card > p {
    max-width: 540px;
  }
}
/* ////////////// Modile Design ////////////// */

@media only screen and (max-width: 426px) {
  .ITManagementSection2 {
    padding: 0 16px;
  }
  .ITManagementSection2-bottom-card > p {
    max-width: 302px;
  }
}
