.ContactUsSection2 {
  margin-top: 64px;
  padding: 0 98px;
}

.ContactUsSection2-title {
  color: #242424;
  font-size: 40px;
  font-weight: 600;
  line-height: 52px;
  letter-spacing: 0em;
  text-align: left;
}

.ContactUsSection2-cards-Container {
  margin-top: 48px;
  display: flex;
  flex-wrap: wrap;
  gap: 32px;
}
/* ////////////// Tablet Design ////////////// */

@media only screen and (max-width: 768px) {
  .ContactUsSection2 {
    margin-top: 32px;
    padding: 0 24px;
  }
  .ContactUsSection2-title {
    font-size: 30px;
    text-align: center;
  }
  .ContactUsSection2-cards-Container {
    margin-top: 32px;
    display: flex;
    gap: 24px;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: flex-start;
  }
  .Section2Card {
    min-height: 440px;
    width: 100%;
  }
}

/* ////////////// Modile Design ////////////// */
@media only screen and (max-width: 426px) {
  .ContactUsSection2 {
    padding: 0 16px;
  }
  .ContactUsSection2-title {
    font-size: 25px;
    font-weight: 600;
    line-height: 38px;
    text-align: center;
  }

  .ContactUsSection2-cards-Container {
    margin-top: 0;
    flex-direction: column;
  }
  .Section2Card {
    min-height: unset;
  }
}
