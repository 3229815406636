.Section2 {
  margin-top: 64px;
  padding: 0 94px;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  gap: 64px;
}

.Section2-left > img {
  width: 599px;
  height: 605px;
  object-fit: cover;
}

.Section2-right {
  text-align: left;
  max-width: 552px;
}

.Section2-right-1 {
  color: #5b5b5b;
  font-size: 25px;
  font-weight: 600;
  line-height: 38px;
  letter-spacing: 0em;
}
.Section2-right-2 {
  margin-top: 16px;
  color: #242424;
  font-size: 40px;
  font-weight: 600;
  line-height: 52px;
  letter-spacing: 0em;
  text-align: left;
}
.Section2-right-2 > span {
  color: #7f68b4;
}
.Section2-right-3 {
  margin-top: 24px;

  color: #5b5b5b;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
}
.Section2-right-4 {
  margin-top: 32px;
  width: 490px;
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
}

.Section2-right-4 > li {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  min-width: 202px;
}

.CheckboxCircle {
  color: rgba(247, 198, 29, 1);
  width: 16px;
  height: 16px;
  margin-right: 16px;
  margin-top: 6px;
}

.Section2-right-5 {
  margin-top: 48px;
}

.Section2-right-5 a {
  text-decoration: none;
  cursor: pointer;
}

.Section2-right-5-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(95, 73, 146, 1);
  color: white;
  width: 238px;
  height: 59px;
  border-radius: 8px;
  font-size: 18px;
  font-weight: 500;
  line-height: 27px;
  letter-spacing: 0em;
}
.Section2-right-5-btn:hover {
  background-color: rgba(84, 58, 147, 1);
}
/* ////////////// Tablet Design ////////////// */

@media only screen and (max-width: 1024px) {
  .Section2 {
    margin-top: 48px;
    padding: 0 24px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
  }
  .Section2-left {
    width: 100%;
  }
  .Section2-left > img {
    width: 100%;
    height: 250px;
    object-fit: cover;
  }
  .Section2-right {
    text-align: center;
    max-width: none;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .Section2-right-1 {
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0em;
  }
  .Section2-right-2 {
    font-size: 25px;
    font-weight: 600;
    line-height: 38px;
    letter-spacing: 0em;
    text-align: center;
  }
  .Section2-right-3 {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: center;
    max-width: 635px;
  }
  .Section2-right-4 {
    margin: auto;
    margin-top: 24px;
    max-width: 474px;
    gap: 16px;
  }
}

/* ////////////// Modile Design ////////////// */

@media only screen and (max-width: 426px) {
  .Section2 {
    margin-top: 32px;
    padding: 0 16px;
    gap: 16px;
  }
  .Section2-right-4 {
    max-width: 343px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .Section2-right-5 {
    margin-top: 32px;
  }
}
