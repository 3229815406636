.OurSolutionContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: rgba(242, 237, 255, 1);
  padding: 48px 97px;
}

.OurSolutionBox {
  width: 100%;
}

.tob-Section {
  margin-bottom: 32px;
}
.tob-Section h1 {
  font-size: 40px;
  font-weight: 600;
  line-height: 52px;
  letter-spacing: 0em;
  text-align: left;
  margin-bottom: 16px;
}

.tob-Section-1 {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  flex-wrap: nowrap;
}

.tob-Section-1-1 p {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  width: 90%;
  margin: 0;
}

.tob-Section-1-2 a {
  text-decoration: none;
  cursor: pointer;
}

.tob-Section-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(95, 73, 146, 1);
  color: white;
  width: 249px;
  height: 59px;
  border-radius: 8px;
  font-size: 18px;
  font-weight: 500;
  line-height: 27px;
  letter-spacing: 0em;
}
.tob-Section-btn:hover {
  background-color: rgba(84, 58, 147, 1);
}

.OurSolutionArrowRightCircleIcon {
  color: white;
  width: 24px;
  height: 24px;
  margin-left: 4px;
}

.OurSolutionBox-bottom-Section {
  margin-top: 48px;
}
.OurSolutionBox-bottom-Section-cards {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 32px;
}

.OurSolutionBox-bottom-Section-cards > div {
  width: 30%;
  max-width: 393px;
}

/* ////////////// for card width ////////////// */
@media only screen and (max-width: 1122px) {
  .OurSolutionBox-bottom-Section-cards > div {
    width: unset;
    max-width: unset;
  }
}

/* ////////////// Tablet Design ////////////// */

@media only screen and (max-width: 768px) {
  .OurSolutionContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 32px 60px;
  }

  .OurSolutionBox {
    display: flex;
    flex-direction: column;
  }
  .tob-Section > h1 {
    text-align: center;
    font-size: 25px;
    font-weight: 600;
    line-height: 38px;
  }

  .tob-Section-1 {
    flex-direction: column;
    align-items: center;
  }

  .tob-Section-1-1 > p {
    width: 100%;
    text-align: center;
  }

  .tob-Section-1-2 {
    margin-top: 16px;
  }
  .tob-Section-btn {
    width: 191px;
    height: 48px;

    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
  }
  .OurSolutionArrowRightCircleIcon {
    width: 16px;
  }
}

/* ////////////// Modile Design ////////////// */

@media only screen and (max-width: 426px) {
  .OurSolutionContainer {
    padding: 32px 16px;
  }
}
