/* Override Swiper pagination styles */
.swiper-pagination {
  color: #ffffff;
  border-radius: 10px;
}

.swiper-pagination-bullet {
  width: 24px;
  height: 4px;
  margin: 0 5px;
  background-color: #ffffff;
  opacity: 50%;
  border-radius: 25px;
}

.swiper-pagination-bullet-active {
  background-color: #fad039;
  opacity: 1;
}
