.SolutionsDropdown {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
}

.dropdownRoute {
  color: black;
  text-decoration: none;
  display: block;
  text-align: left;
  font-size: 16px;
  font-weight: 500;
  /* line-height: 24px; */
  letter-spacing: 0em;
  padding: 12px;
  border-radius: 6px;
}

.dropdownRoute:hover {
  background-color: rgba(242, 237, 255, 1);
}

.activeDropdownRoute {
  background-color: rgba(242, 237, 255, 1);
  font-weight: 500;
}

.dropDownCard {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-evenly;
  align-items: flex-start;
  gap: 16px;
  max-width: 360px;
}

.dropDownCard-right {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  text-decoration: none;
}

.dropDownCardImg {
  max-width: 148px;
  border-radius: 6px;
}

.dropDownCardTitle {
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 0.25rem;
}

.dropDownCardDescription {
  font-size: 12px;
  /* line-height: 1.3; */
  color: #475467;
}
