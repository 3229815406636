.DataAndAIServicesSection3 {
  padding: 48px 98px;
  background-color: #f2edff;
}

.DataAndAIServicesSection3-top {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  gap: 48px;
}

.DataAndAIServicesSection3-top-left {
  text-align: left;
  color: #5b5b5b;
  max-width: 596px;
}

.DataAndAIServicesSection3-top-left-1 {
  font-size: 25px;
  font-weight: 600;
  line-height: 38px;
  letter-spacing: 0em;
  color: #242424;
}

.DataAndAIServicesSection3-top-left-2 {
  font-size: 18px;
  font-weight: 500;
  line-height: 27px;
  letter-spacing: 0em;
  margin-top: 16px;
  color: #373737;
}

.DataAndAIServicesSection3-top-left-3 {
  font-size: 15px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  margin-top: 16px;
}

.DataAndAIServicesSection3-top-left-4 {
  margin-top: 32px;
}
.DataAndAIServicesSection3-top-left-4 > ul {
  margin: 0;
}

.DataAndAIServicesSection3-top-left-4 > ul > li {
  margin-bottom: 16px;
  font-size: 15px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
}

.DataAndAIServicesSection3-top-right > img {
  max-width: 597px;
}

/* ///////////Mid/////////// */

.DataAndAIServicesSection3-mid {
  margin-top: 32px;
  text-align: left;
  color: #5b5b5b;
}

.DataAndAIServicesSection3-mid-1 {
  color: #242424;
  font-size: 25px;
  font-weight: 600;
  line-height: 38px;
  letter-spacing: 0em;
}

.DataAndAIServicesSection3-mid-2 {
  font-size: 18px;
  font-weight: 500;
  line-height: 27px;
  letter-spacing: 0em;
  margin-top: 16px;
  color: #373737;
}
.DataAndAIServicesSection3-mid-3 {
  font-size: 15px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  max-width: 731px;
  margin-top: 16px;
}
.DataAndAIServicesSection3-mid-4 {
  margin-top: 32px;
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  gap: 16px;
}

.DataAndAIServicesSection3-mid-4 > ul {
  /* min-width: 528px; */
  margin: 0;
}
.DataAndAIServicesSection3-mid-4 > ul > li {
  margin-bottom: 16px;
  font-size: 15px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
}

/* //////////////////////// */

/* bottom */
.DataAndAIServicesSection3-bottom {
  margin-top: 16px;
}

.DataAndAIServicesSection3-bottom > a {
  text-decoration: none;
}

.DataAndAIServicesSection3-bottom-btn {
  width: 232.47px;
  height: 59px;
  border-radius: 8px;
  background-color: rgba(95, 73, 146, 1);
  color: white;
  font-size: 18px;
  font-weight: 500;
  line-height: 27px;
  letter-spacing: 0em;
  text-align: left;
  display: flex;
  align-items: center;
  justify-content: center;
}
/* ////////////// Tablet Design ////////////// */

@media only screen and (max-width: 768px) {
  .DataAndAIServicesSection3 {
    padding: 32px 24px;
  }
  .DataAndAIServicesSection3-top {
    flex-direction: column-reverse;
    gap: 24px;
  }
  .DataAndAIServicesSection3-top-right {
    width: 100%;
  }
  .DataAndAIServicesSection3-top-right > img {
    width: 100%;
    max-width: none;
    height: 250px;
    object-fit: cover;
  }
  .DataAndAIServicesSection3-mid-4 {
    gap: 0px;
  }
  .DataAndAIServicesSection3-bottom-btn {
    width: 161px;
    height: 48px;
    font-size: 15px;
    font-weight: 500;
    line-height: 24px;
  }
}

/* ////////////// Modile Design ////////////// */

@media only screen and (max-width: 426px) {
  .DataAndAIServicesSection3 {
    padding: 32px 16px;
  }
}
