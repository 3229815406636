.DigitalBusinessSection1 {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  align-items: flex-start;
  gap: 48px;
  padding: 0 98px;
}
.DigitalBusinessSection1-text {
  text-align: left;
}
.DigitalBusinessSection1-text-1 {
  color: #242424;
  font-size: 25px;
  font-weight: 600;
  line-height: 38px;
  letter-spacing: 0em;
  text-align: left;
}
.DigitalBusinessSection1-text-2 {
  color: #5b5b5b;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  margin-top: 32px;
  max-width: 572px;
}
.DigitalBusinessSection1-text-3 {
  color: #5b5b5b;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  margin-top: 32px;
  max-width: 624px;
}

.DigitalBusinessSection1-text-3 > ul > li > a {
  color: #5277f7;
}

.DigitalBusinessSection1-image > img {
  width: 572px;
  border-radius: 6px;
}
/* ////////////// Tablet and mini labtop Design ////////////// */
@media only screen and (max-width: 1024px) {
  .DigitalBusinessSection1 {
    display: flex;
    flex-direction: column-reverse;
    gap: 32px;
    padding: 0 24px;
  }
  .DigitalBusinessSection1-image {
    width: 100%;
  }
  .DigitalBusinessSection1-image > img {
    width: 100%;
    height: 250px;
    object-fit: cover;
  }
  .DigitalBusinessSection1-text-2 {
    margin-top: 16px;
  }
  .DigitalBusinessSection1-text-3 {
    margin-top: 24px;
  }
}

/* ////////////// Mobile Design ////////////// */
@media only screen and (max-width: 426px) {
  .DigitalBusinessSection1 {
    padding: 0 16px;
  }
}
