.DataAndAIServicesSection2 {
  background-color: #f2edff;
  padding: 32px 101px 0 101px;
  text-align: left;
}

.DataAndAIServicesSection2-1 {
  color: #242424;
  font-size: 25px;
  font-weight: 600;
  line-height: 38px;
  letter-spacing: 0em;
}
.DataAndAIServicesSection2-2 {
  color: #666666;
  font-size: 15px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  margin-top: 16px;
  max-width: 907px;
}
.DataAndAIServicesSection2-3 {
  margin-top: 32px;
  color: #494949;
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: flex-start;
  gap: 32px;
}

.DataAndAIServicesSection2-3-square {
  display: flex;
  justify-content: space-evenly;
  flex-direction: column;
  align-items: flex-start;
}

.DataAndAIServicesSection2-3-card {
  display: flex;
  align-items: flex-start;
  margin-bottom: 32px;
}

.DataAndAIServicesSection2-3-card > p {
  margin-left: 24px;
  margin-bottom: 0px;
  max-width: 554px;
  font-size: 15px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
}

.DataAndAIServicesSection2-3-card > p > span {
  font-weight: bold;
}
/* ////////////// Tablet Design ////////////// */

@media only screen and (max-width: 768px) {
  .DataAndAIServicesSection2 {
    padding: 0 24px;
    margin: 0;
  }
  .DataAndAIServicesSection2-3 {
    gap: 0;
  }
  .DataAndAIServicesSection2-3-card > p {
    max-width: 542px;
  }
}

/* ////////////// Modile Design ////////////// */

@media only screen and (max-width: 426px) {
  .DataAndAIServicesSection2 {
    padding: 0 16px;
  }
  .DataAndAIServicesSection2-3-card > p {
    max-width: 303px;
  }
}
