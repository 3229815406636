.react-multi-carousel-track {
  align-items: center;
}
.react-multi-carousel-dot-list {
  position: static;
}

.react-multiple-carousel__arrow,
.react-multiple-carousel__arrow:hover {
  background: transparent;
}
.react-multiple-carousel__arrow::before {
  color: #575757;
  font-size: 35px;
  font-weight: 600;
}
.react-multiple-carousel__arrow--left {
  left: 0;
}
.react-multiple-carousel__arrow--right {
  right: 0;
}

.carousel-item-class {
  height: 72px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.carousel-element-container {
  max-width: -webkit-fill-available;
  margin: 0 8px;
}

/* ////////////// Tablet Design ////////////// */

@media only screen and (max-width: 768px) {
  .react-multiple-carousel__arrow::before {
    font-size: 29px;
  }
}

/* ////////////// Modile Design ////////////// */

@media only screen and (max-width: 376px) {
}
