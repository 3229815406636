.SuccessModal {
  background-color: white;
  max-width: 658px !important;
  max-height: 693.58px;
  margin-bottom: 0 !important;
  border-radius: 8px;
}
.SuccessModalBody {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 16px 48px;
}

.SuccessModalBody-1 {
  width: 100%;
}

.SuccessModalBody-2 {
  margin-top: 32px;
  font-size: 32px;
  font-weight: 600;
  line-height: 48px;
  letter-spacing: 0em;
}

.SuccessModalBody-3 {
  margin-top: 32px;
  font-size: 18px;
  font-weight: 500;
  line-height: 27px;
  letter-spacing: 0em;
  color: #5b5b5b;
}

.SuccessModalBody-4 {
  margin-top: 48px;
}
.SuccessModalBody-4 a {
  text-decoration: none;
  cursor: pointer;
}
.SuccessModalBody-4-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #5f4992;
  color: white;
  width: 359px;
  height: 59px;
  border-radius: 8px;
  font-size: 18px;
  font-weight: 500;
  line-height: 27px;
  letter-spacing: 0em;
}
/* ////////////// Tablet Design ////////////// */

@media only screen and (max-width: 768px) {
}

/* ////////////// Modile Design ////////////// */

@media only screen and (max-width: 376px) {
  .SuccessModal {
    width: 343px;
    height: 498.58px;
  }
  .SuccessModalBody {
    width: 100%;
    padding: 16px;
    display: flex;
    align-items: center;
  }
  .SuccessModalBody-1 > img {
    width: 235px;
  }
  .SuccessModalBody-2 {
    margin-top: 16px;
    font-size: 18px;
    font-weight: 600;
    line-height: 27px;
    letter-spacing: 0em;
  }
  .SuccessModalBody-3 {
    max-width: 279px;
    margin-top: 16px;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
  }
  .SuccessModalBody-4 {
    margin-top: 16px;
  }
  .SuccessModalBody-4-btn {
    width: 279px;
    height: 59px;

    font-size: 18px;
    font-weight: 500;
    line-height: 27px;
    letter-spacing: 0em;
  }
}
