.DigitalBusinessSection4 {
  padding: 48px 98px;
}
.DigitalBusinessSection4-top {
  text-align: left;
}
.DigitalBusinessSection4-top-title {
  color: rgba(36, 36, 36, 1);
  font-size: 25px;
  font-weight: 600;
  line-height: 38px;
  letter-spacing: 0em;
  text-align: left;
}
.DigitalBusinessSection4-top-description {
  color: rgba(55, 55, 55, 1);
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  margin-top: 16px;
  max-width: 693px;
}

.DigitalBusinessSection4-mid {
  margin-top: 32px;
}

.DigitalBusinessSection4-mid-card {
  text-align: left;
  margin-bottom: 32px;
  max-width: 560px;
}

.DigitalBusinessSection4-mid-card-title {
  color: rgba(127, 104, 180, 1);
  font-size: 18px;
  font-weight: 600;
  line-height: 27px;
  letter-spacing: 0em;
  text-align: left;
}

.DigitalBusinessSection4-mid-card-description {
  color: rgba(91, 91, 91, 1);
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  margin-top: 16px;
}

.DigitalBusinessSection4-bottom > a {
  text-decoration: none;
}
.DigitalBusinessSection4-bottom-btn {
  width: 232.47px;
  height: 59px;
  border-radius: 8px;
  background-color: rgba(95, 73, 146, 1);
  color: white;
  font-size: 18px;
  font-weight: 500;
  line-height: 27px;
  letter-spacing: 0em;
  text-align: left;
  display: flex;
  align-items: center;
  justify-content: center;
}
.ArrowRightCircleIcon {
  color: white;
  width: 24px;
  height: 24px;
  margin-left: 4px;
}
/* ////////////// Tablet Design ////////////// */
@media only screen and (max-width: 768px) {
  .DigitalBusinessSection4 {
    padding: 48px 40px;
  }
  .DigitalBusinessSection4-bottom-btn {
    width: 161px;
    height: 48px;

    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0em;
  }
  .ArrowRightCircleIcon {
    width: 16px;
    height: 16px;
  }
}

/* ////////////// Mobile Design ////////////// */
@media only screen and (max-width: 426px) {
  .DigitalBusinessSection4 {
    padding: 32px 16px;
  }
  .responsive-row {
    display: flex;
    flex-direction: column;
  }
}
