.CustomersOpinionContainer {
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: #f2edff;
  padding-top: 32px;
  padding-bottom: 48px;
}
.CustomersOpinionTobSection {
  max-width: 898px;
}

.CustomersOpinionTobSection-1 {
  font-size: 25px;
  font-weight: 600;
  line-height: 38px;
  letter-spacing: 0em;
  text-align: center;
  color: #5b5b5b;
}

.CustomersOpinionTobSection-2 {
  font-size: 40px;
  font-weight: 600;
  line-height: 52px;
  letter-spacing: 0em;
  text-align: center;
  color: linear-gradient(0deg, #242424, #242424);
}
.CustomersOpinionTobSection-2 div {
  background: -webkit-linear-gradient(0deg, #7f68b4, #7f68b4);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.CustomersOpinionTobSection-3 {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: center;
  color: #5b5b5b;
  margin: auto;
  margin-top: 32px;
  max-width: 581px;
}

.CustomersOpinionMedSection {
  margin-top: 32px;
  width: 100%;
}

.CustomersOpinionbottomSection {
  margin-top: 48px;
}
.CustomersOpinionbottomSection a {
  text-decoration: none;
  cursor: pointer;
}
.CustomersOpinionbottomSection-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #5f4992;
  color: white;
  width: 222px;
  height: 59px;
  border-radius: 8px;
  font-size: 18px;
  font-weight: 500;
  line-height: 27px;
  letter-spacing: 0em;
}
.CustomersOpinionbottomSection-btn:hover {
  background-color: rgba(84, 58, 147, 1);
}

/* ////////////// Tablet Design ////////////// */

@media only screen and (max-width: 768px) {
  .CustomersOpinionContainer {
    padding: 32px 24px;
  }
  .CustomersOpinionTobSection-1 {
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
  }

  .CustomersOpinionTobSection-2 {
    font-size: 25px;
    line-height: 38px;
  }

  .CustomersOpinionTobSection-3 {
    margin-top: 16px;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: center;
  }
}

/* ////////////// Modile Design ////////////// */

@media only screen and (max-width: 768px) {
  .CustomersOpinionbottomSection-btn {
    width: 167px;
    height: 48px;
    font-size: 16px;
    line-height: 24px;
  }
}
