.SolutionsHeader {
  width: 100%;
  background-image: url("SolutionsHeaderImg.svg");
  height: 355px;
  /* display: flex; */
}

.SolutionsHeaderText {
  padding-top: 215px;
  padding-left: 98px;
  text-align: left;
}

.SolutionsHeaderText > h1 {
  color: rgba(255, 255, 255, 1);
  font-size: 40px;
  font-weight: 600;
  line-height: 52px;
  letter-spacing: 0em;
  margin: 0;
}

.SolutionsHeaderPath {
  display: flex;
  flex-direction: row;
  color: rgba(200, 200, 200, 1);
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  margin-top: 16px;
}
.SolutionsHeaderPath-last {
  color: rgba(247, 198, 29, 1);
  margin-left: 5px;
}

@media only screen and (max-width: 768px) {
  .SolutionsHeader {
    height: 208px;
  }
  .SolutionsHeaderText {
    padding: 125px 0 0 24px;
  }

  .SolutionsHeaderText > h1 {
    font-size: 18px;
    font-weight: 600;
    line-height: 27px;
  }

  .SolutionsHeaderPath {
    margin-top: 8px;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
  }
}

/* ////////////// Mobile Design ////////////// */
@media only screen and (max-width: 426px) {
  .SolutionsHeader {
    height: 181px;
  }
  .SolutionsHeaderText {
    padding: 94px 0 0 16px;
  }
  .SolutionsHeaderText > h1 {
    font-size: 18px;
    font-weight: 600;
    line-height: 27px;
  }

  .SolutionsHeaderPath {
    margin-top: 8px;
    font-size: 14px;
    font-weight: 500;
    line-height: 24px;
  }
}
