.modal {
  text-align: center;
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
}

.modal-content {
  background-color: transparent !important;
  border: none !important;
}

.modal-body {
  display: flex;
  align-items: center;
}

.searchBox {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  border: 2px white solid;
  border-radius: 5px;
  width: 100%;
  padding: 10px 20px;
  height: 70px;
}

.searchInput {
  background-color: transparent;
  border: none;
  width: 100%;
  color: white;
  font-size: 24px;
}

.searchInput::-webkit-search-cancel-button {
  -webkit-appearance: none;
  appearance: none;
  height: 20px;
  width: 20px;
  background-image: url("../../images/x-mark-16.gif");
  background-size: 20px 20px;
  cursor: pointer;
  margin-right: 10px;
}

.searchInput::placeholder {
  color: white;
}
.searchInput:focus {
  outline: none;
}
