.OurTechnologyContainer {
  margin-bottom: 100px;
  padding: 0px 98px;
}
.OurTechnologyTopSection {
  text-align: center;
}
.TopSectionTitle-1 {
  font-size: 40px;
  font-weight: 600;
  line-height: 52px;
  letter-spacing: 0em;
  text-align: center;
}
.TopSectionTitle-2 {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: center;
  color: #5f6d7e;
  margin-top: 16px;
}
.OurTechnologybottomSection {
  margin-top: 48px;
}
.OurTechnologybottomSection > a {
  text-decoration: none;
  cursor: pointer;
}

.OurTechnologyCarousalImg {
  width: 50%;
  max-width: 200px;
}

.OurTechnology-btn-container {
  margin-top: 32px;
}

.OurTechnology-btn {
  max-width: fit-content;
  margin: auto;
}

.OurTechnologies-btn {
  text-decoration: none;
  cursor: pointer;
  color: #5f4992;
  font-size: 18px;
  font-weight: 500;
  line-height: 27px;
  display: flex;
  align-items: center;
  gap: 4px;
}

.OurTechnologyArrowRightCircleIcon {
  color: #5f4992;
  width: 24px;
}

/* ////////////// Tablet Design ////////////// */

@media only screen and (max-width: 768px) {
  .OurTechnologyContainer {
    padding: 0px 24px;
  }
  .TopSectionTitle-1 {
    font-size: 25px;
    font-weight: 600;
    line-height: 38px;
    text-align: center;
  }

  .TopSectionTitle-2 {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: center;
  }

  .OurTechnologybottomSection {
    margin-top: 32px;
  }
  .OurTechnologyLogo {
    max-width: 100px;
  }
}

/* ////////////// Modile Design ////////////// */

@media only screen and (max-width: 426px) {
  .OurTechnologyContainer {
    padding: 0px 16px;
    margin-bottom: 32px;
  }
  .OurTechnologySwiper {
    height: 135px;
  }
  .OurTechnologySwiperSlide {
    max-width: 100px;
    max-height: 100px;
  }
  .OurTechnologies-btn {
    margin-top: 0;
    width: 171px;
    height: 50px;
    font-size: 16px;
  }
}
