.trustedContainer {
  padding: 64px 98.5px;
}

.trustedTitle-1 {
  font-size: 40px;
  font-weight: 600;
  line-height: 52px;
  letter-spacing: 0em;
  text-align: center;
  margin-bottom: 16px;
}
.spcialColor {
  background: -webkit-linear-gradient(0deg, #7f68b4, #7f68b4);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.trustedTitle-2 {
  color: #5b5b5b;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: center;
  margin-bottom: 32px;
}
.trustedTitle-3 {
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  letter-spacing: 0em;
}

.list-horizontal {
  margin: 0;
  padding: 0;
}

.list-horizontal li {
  display: inline-block;
}
.list-horizontal li:before {
  content: "\00a0\2022\00a0\00a0";
  color: black;
  /* font-size: 11px; */
}

.Trusted-list-horizontal-rightBorder::after {
  content: " |";
  color: black;
  margin-left: 2px;
  /* font-size: 11px; */
}

.trustedCarousal {
  margin-top: 48px;
  height: 72px;
}

.trustedCarousalImg {
  max-width: -webkit-fill-available;
  max-height: 70px;
}

.Clients-btn-container {
  margin-top: 32px;
}

.Clients-btn {
  max-width: fit-content;
  margin: auto;
}

.trusted-btn {
  text-decoration: none;
  cursor: pointer;
  color: #5f4992;
  font-size: 18px;
  font-weight: 500;
  line-height: 27px;
  display: flex;
  align-items: center;
  gap: 4px;
}

.ClientsArrowRightCircleIcon {
  color: #5f4992;
  width: 24px;
}

/* ////////////// Tablet Design ////////////// */

@media only screen and (max-width: 768px) {
  .trustedContainer {
    padding: 48px 24px;
  }
  .trustedTextBox {
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 543.5px;
  }
  .trustedTitle-1 {
    font-size: 25px;
    line-height: 38px;
    margin-bottom: 8px;
  }
  .trustedTitle-2 {
    margin-bottom: 16px;
  }
  .trustedCarousal {
    margin-top: 24px;
  }
}

/* ////////////// Modile Design ////////////// */

@media only screen and (max-width: 376px) {
  .trustedContainer {
    padding: 32px 16px;
  }
  .trustedTitle-3 {
    font-size: 13px;
  }
}
