@keyframes pop-in {
  0% {
    opacity: 0;
    transform: scale(0.1);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

.OurSolutionHoverCardContainer {
  position: relative;
  height: 420px;
  border-radius: 8px;
  overflow: hidden;
  cursor: default;
}

.add-pop-in-animation {
  animation: pop-in 0.5s;
}

.OurSolutionHoverCardImage {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: 0.5s ease;
  backface-visibility: hidden;
  transition: 0.1s all ease-in-out;
}

.OurSolutionHoverCardContainer:hover img {
  transform: scale(1.1);
}

.OurSolutionHoverCardTitle {
  position: absolute;
  bottom: 27px;
  left: 24px;
  opacity: 1;
  /* top: 85%; */
  /* width: 100%; */
  /* height: 100%; */
  /* display: flex; */
  /* flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start; */
  /* transition: 0.5s ease; */
  /* transform: translate(-50%, -50%); */
  /* -ms-transform: translate(-50%, -50%); */
}

.OurSolutionHoverCardTitle > div {
  color: white;
  font-size: 25px;
  font-weight: 600;
  line-height: 38px;
  letter-spacing: 0em;
  text-align: left;
}

.OurSolutionHoverCardMiddle {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: 0.5s ease;
  opacity: 0;
  text-align: center;
  /* transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%); */
}

/* .OurSolutionHoverCardContainer:hover .OurSolutionHoverCardImage {
  opacity: 0.3;
} */

.OurSolutionHoverCardContainer:hover .OurSolutionHoverCardTitle {
  opacity: 0;
}
.OurSolutionHoverCardContainer:hover .OurSolutionHoverCardMiddle {
  opacity: 1;
}

.OurSolutionHoverCardMiddleText {
  width: 100%;
  height: 100%;
  background-color: #543a9380;
  border-radius: 8px;
  color: white;
  font-size: 16px;
  text-align: left;
  padding: 60px 24px 0 24px;
}

.OurSolutionHoverCardMiddleText > h3 {
  font-size: 18px;
  font-weight: 600;
  line-height: 27px;
  letter-spacing: 0em;
  text-align: left;
  margin: 22px 0 0 0;
}

.OurSolutionHoverCardMiddleText > p {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  margin: 16px 0 0 0;
}

.OurSolutionHoverCardMiddleText > div {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 156px;
  height: 48px;
  margin-top: 16px;
  border: 1px solid white;
  border-radius: 8px;
}
.OurSolutionHoverCardMiddleText > div > a {
  color: white;
  text-decoration: none;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0em;
}

/* ////////////// for card width ////////////// */
@media only screen and (max-width: 1200px) {
  .OurSolutionHoverCardTitle > div {
    font-size: 22px;
  }

  .OurSolutionHoverCardMiddleText {
    padding: 45px 26px 0 26px;
  }

  .OurSolutionHoverCardMiddleText > h3 {
    margin-top: 16px;
  }

  .OurSolutionHoverCardMiddleText > p {
    margin-top: 8px;
  }

  .OurSolutionHoverCardMiddleText > div {
    margin-top: 24px;
    width: 195.65px;
    height: 54px;
  }
  .OurSolutionHoverCardMiddleText > div > a {
    font-size: 18px;
    font-weight: 500;
    line-height: 27px;
  }
}

/* ////////////// for card width ////////////// */
@media only screen and (max-width: 1122px) {
  .OurSolutionHoverCardContainer {
    width: 348px;
    height: 400px;
  }
}

/* ////////////// Mobile Design ////////////// */
@media only screen and (max-width: 426px) {
}
