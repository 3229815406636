.overlayNav {
  height: 0%;
  width: 100%;
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  background-color: rgb(0, 0, 0);
  background-color: rgba(84, 58, 147, 1);
  overflow-y: hidden;
  transition: 0.4s;
}

.closebtn {
  padding: 8px;
  text-decoration: none;
  font-size: 36px;
  color: #ffffff;
  display: block;
  transition: 0.3s;
  cursor: pointer;
}

.overlayNav-content {
  position: relative;
  top: 108px;
  width: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 64px;
}

.overlayBottom {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
}

.overlayNav a:hover,
.overlayNav a:focus {
  color: #f1f1f1;
}

.overlayNav .closebtn {
  position: absolute;
  top: 20px;
  right: 45px;
}

.overlaySearchBar-container {
  width: 100%;
}

.overlaySearchBar {
  width: 426px;
  height: 48px;
  border-radius: 6px;
  border: 1px solid rgb(255 255 255);
  background-color: white;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 12px 16px 12px 0;
}

.overlaySearchBarInput {
  width: -webkit-fill-available;
  height: inherit;
  border: 0;
  border-radius: 6px;
  padding: 0 16px;
}

.overlaySearchBarInput:focus {
  outline: none;
}

.overlayOptions {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
}

.overlayOption {
  text-decoration: none;
  color: white;
  font-size: 24px;
  font-weight: 500;
  line-height: 27px;
  letter-spacing: 0em;
  text-align: center;
}

.activeOption {
  color: #f7c61d;
}

/* ////////////// Tablet Design ////////////// */
@media only screen and (min-width: 768px) {
  .overlayNav {
    height: 0%;
  }
}

/* ////////////// Modile L Design ////////////// */
@media only screen and (max-width: 426px) {
  .overlaySearchBar-container {
    width: 100%;
  }
  .overlaySearchBar {
    width: 100%;
    padding: 0 16px;
  }
  .overlayOption {
    font-size: 18px;
  }
}

/* ////////////// Modile M Design ////////////// */
@media only screen and (max-width: 376px) {
  .overlaySearchBar {
    width: 343px;
  }
}
