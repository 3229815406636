.IT_Solutions {
  margin-top: 60px;
  padding: 0 98px;
  width: 100%;
  display: flex;
  flex-direction: column;
}
.IT_Solutions-text {
  text-align: left;
  max-width: 1075px;
}
.IT_Solutions-text-1 {
  font-size: 32px;
  font-weight: 600;
  line-height: 48px;
  letter-spacing: 0em;
  color: #242424;
  max-width: 596px;
}
.IT_Solutions-text-1 > span {
  color: #7f68b4;
}
.IT_Solutions-text-2 {
  margin-top: 16px;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  color: #5b5b5b;
}

.IT_Solutions-bottom {
  margin-top: 48px;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  gap: 32px;
}

.IT_Solutions-bottom > div {
  width: 30%;
  max-width: 393px;
}

/* ////////////// for card width ////////////// */
@media only screen and (max-width: 1122px) {
  .IT_Solutions-bottom > div {
    width: unset;
    max-width: unset;
  }
}

/* ////////////// Tablet Design ////////////// */
@media only screen and (max-width: 768px) {
  .IT_Solutions {
    margin-top: 48px;
    padding: 0 24px;
  }
  .IT_Solutions-text {
    text-align: center;
  }
  .IT_Solutions-text-1 {
    margin: auto;
    font-size: 25px;
    font-weight: 600;
    line-height: 38px;
    max-width: 541px;
  }
  .IT_Solutions-bottom {
    margin-top: 24px;
    gap: 24px;
  }
}

@media only screen and (max-width: 426px) {
  .IT_Solutions {
    margin-top: 32px;
    padding: 0 16px;
  }
  .IT_Solutions-bottom {
    flex-direction: column;
  }
}
