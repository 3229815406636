.DigitalBusinessSection3 {
  margin-top: 32px;
  background-color: rgba(242, 237, 255, 1);
  padding: 32px 98px;
}
.DigitalBusinessSection3-text {
  text-align: left;
  max-width: 553px;
}
.DigitalBusinessSection3-text-1 {
  color: rgba(36, 36, 36, 1);
  font-size: 25px;
  font-weight: 600;
  line-height: 38px;
  letter-spacing: 0em;
}
.DigitalBusinessSection3-text-2 {
  margin-top: 16px;
  color: rgba(55, 55, 55, 1);
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
}

.DigitalBusinessSection3-bottom {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  gap: 64px;
}

.DigitalBusinessSection3-list {
  margin-top: 32px;
  max-width: 528px;
  color: rgba(91, 91, 91, 1);
}

.DigitalBusinessSection3-list > ul > li {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  margin-bottom: 16px;
}
.DigitalBusinessSection3-bottom-right {
  text-align: right;
}
.DigitalBusinessSection3-bottom-right > img {
  max-width: 418px;
}
/* ////////////// Tablet Design ////////////// */
@media only screen and (max-width: 768px) {
  .DigitalBusinessSection3 {
    margin-top: 48px;
    padding: 32px 24px;
  }
  .DigitalBusinessSection3-bottom {
    display: flex;
    flex-direction: column-reverse;
    gap: 16px;
  }
  .DigitalBusinessSection3-list {
    margin: 0;
  }
  .DigitalBusinessSection3-bottom-right > img {
    max-width: 182px;
  }
}

/* ////////////// Mobile Design ////////////// */
@media only screen and (max-width: 426px) {
  .DigitalBusinessSection3-bottom-right {
    width: 100%;
    text-align: center;
  }
  .DigitalBusinessSection3-bottom-right > img {
    max-width: 271px;
  }
}
