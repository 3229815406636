.DigitalBusinessAutomationSections {
  margin-top: 60px;
  max-width: 1441px;
}
/* ////////////// Tablet Design ////////////// */
@media only screen and (max-width: 768px) {
  .DigitalBusinessAutomationSections {
    margin-top: 48px;
  }
}

/* ////////////// Mobile Design ////////////// */
@media only screen and (max-width: 426px) {
  .DigitalBusinessAutomationSections {
    margin-top: 32px;
  }
}
