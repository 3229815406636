.navContainer {
  width: 100%;
  padding: 10px 40px;
  flex-wrap: nowrap;
  position: fixed;
  z-index: 9999;
}

.navContainer.scrolled {
  background-color: white;
  box-shadow: 0px 1px 10px 0px #00000040;
}

.divContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: space-between;
  align-items: center;
}

.navLogo {
  width: 162px;
  height: 54.01px;
}

.optionsContainer {
  display: flex;
  align-items: center;
}

.optionsContainer ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
}

.optionsContainer li {
  float: left;
  cursor: pointer;
}

.routeStyle {
  position: relative;
  display: block;
  color: white;
  text-align: center;
  margin: 14px 16px;
  text-decoration: none;

  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
}

.routeStyle:after {
  content: "";
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: #f7c61d;
  transform-origin: bottom right;
  transition: transform 0.25s ease-out;
}

/* Scale from Start */
.routeStyle:hover:after,
.routeStyle:hover,
.routeStyle.scrolled:hover,
.activeRoute {
  color: #f7c61d;
  transform: scaleX(1);
  transform-origin: bottom left;
}

/* //// Scale from center //// */
/* .routeStyle::after {
  opacity: 1;
  transform: scale(0);
  transform-origin: center;
}

.routeStyle:hover::after {
  transform: scale(1);
} */

/* route Style when Scrolled  */
.routeStyle.scrolled {
  color: #494949;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
}

.activeRoute,
.routeStyle.scrolled.activeRoute {
  color: #f7c61d;
  font-weight: 600;
}

/* dropdown */
.dropDownContainer {
  display: none;
  position: absolute;
  background-color: rgba(255, 255, 255, 1);
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  /* z-index: 1; */
  border-radius: 6px;
  padding: 8px;
}

.Solutions-dropdown:hover .dropDownContainer {
  display: block;
}

.SearchIconStyle {
  display: flex;
  align-items: center;
  padding: 14px 16px;
  cursor: pointer;
}

.SearchButton {
  border: none;
  background: none;
}

.SearchIcon {
  color: white;
  width: 24px;
  height: 24px;
  align-content: center;
}

.SearchIcon.scrolled {
  color: #494949;
}

.menu {
  display: none;
}

/* //////////////// special for nav bar //////////////// */
@media only screen and (max-width: 960px) {
  .optionsContainer {
    display: none;
  }
  .menu {
    display: block;
  }
  .MenueBtn {
    width: 24px;
    height: 24px;
    color: white;
  }
  .MenueBtn.scrolled {
    color: #373737;
  }
}

/* //////////////// Tablet Design //////////////// */
@media only screen and (max-width: 768px) {
  .navContainer {
    padding: 16px 24px;
  }

  .navLogo {
    width: 91.13px;
    height: 30.38px;
  }
}
/* ////////////// Modile Design ////////////// */

@media only screen and (max-width: 376px) {
  .navContainer {
    padding: 17px 16px;
  }
}
