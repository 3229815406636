.whyChooseUsContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 120px 265.5px;
}

.sectoion-1 {
  margin-bottom: 32px;
}
.sectoion-1 h1 {
  font-size: 40px;
  font-weight: 600;
  line-height: 52px;
  letter-spacing: 0em;
  text-align: center;
  margin-bottom: 16px;
}
.sectoion-1 p {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: center;
}

.grid-container {
  display: grid;
  gap: 50px 50px;
  grid-template-columns: auto auto auto;
  padding: 10px;
}

.grid-item {
  border: none;
  padding: 20px;
  font-size: 30px;
  text-align: center;
  padding-right: 50px;
}

.right-border {
  border-right: 1px solid rgba(218, 212, 233, 10);
}

.CounterNumber {
  color: rgba(84, 58, 147, 1);
  font-size: 48px;
  font-weight: 600;
  line-height: 72px;
  letter-spacing: 0em;
  text-align: center;
}

.grid-item p {
  color: rgba(55, 55, 55, 1);
  font-size: 18px;
  font-weight: 500;
  line-height: 27px;
  letter-spacing: 0em;
  text-align: center;
}
/* ////////////// Tablet Design ////////////// */

@media only screen and (max-width: 768px) {
  .whyChooseUsContainer {
    margin-top: 48px;
    padding: 0 69.5px;
  }
}

/* ////////////// Modile Design ////////////// */

@media only screen and (max-width: 426px) {
  .whyChooseUsContainer {
    padding: 0 16px;
  }

  .sectoion-1 h1 {
    font-size: 25px;
    line-height: 38px;
  }
  .sectoion-1 p {
    font-size: 16px;
    line-height: 24px;
  }

  .grid-container {
    display: grid;
    gap: 32px 79px;
    grid-template-columns: auto auto;
    padding: 0;
  }
  .grid-item {
    padding: 0;
    width: 100px;
  }
  .right-border {
    border: 0;
  }
  .grid-item h3 {
    font-size: 32px;
    font-weight: 600;
    line-height: 48px;
  }
  .grid-item p {
    font-size: 14px;
    font-weight: 600;
    line-height: 21px;
  }
}
