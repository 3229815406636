.DataAndAIServicesSection4 {
  padding: 36px 98px;
  text-align: left;
  color: #5b5b5b;
}

.DataAndAIServicesSection4-header {
  font-size: 25px;
  font-weight: 600;
  line-height: 38px;
  letter-spacing: 0em;
  color: #242424;
}

.DataAndAIServicesSection4-definitions {
  margin-top: 16px;
  display: grid;
  grid-template-columns: auto auto;
  justify-content: start;
  align-items: start;
  column-gap: 60px;
  row-gap: 32px;
}

.DataAndAIServicesSection4-card {
  text-align: left;
  max-width: 619px;
  min-width: 320px;
}
.DataAndAIServicesSection4-title {
  color: rgba(127, 104, 180, 1);
  font-size: 18px;
  font-weight: 600;
  line-height: 27px;
  letter-spacing: 0em;
  text-align: left;
}
.DataAndAIServicesSection4-description {
  color: rgba(91, 91, 91, 1);
  font-size: 15px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  margin-top: 16px;
}

@media only screen and (max-width: 768px) {
  .DataAndAIServicesSection4-definitions {
    grid-template-columns: auto;
  }
}
/* ////////////// Tablet Design ////////////// */

@media only screen and (max-width: 768px) {
  .DataAndAIServicesSection4 {
    padding: 0 24px;
  }
}

/* ////////////// Modile Design ////////////// */

@media only screen and (max-width: 426px) {
  .DataAndAIServicesSection4 {
    padding: 0 16px;
  }
}
