.DigitalBusinessSection2 {
  margin-top: 64px;
  padding: 0 98px;
  text-align: left;
}
.DigitalBusinessSection2-text-1 {
  color: #242424;
  font-size: 25px;
  font-weight: 600;
  line-height: 38px;
  letter-spacing: 0em;
  text-align: left;
}
.DigitalBusinessSection2-text-2 {
  margin-top: 16px;
  color: #666666;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  max-width: 720px;
}
.DigitalBusinessSection2-text-3 {
  margin-top: 32px;
  color: #494949;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
  gap: 64px;
}

.DigitalBusinessSection2-text-3-left,
.DigitalBusinessSection2-text-3-right {
  display: flex;
  justify-content: space-evenly;
  flex-direction: column;
  align-items: flex-start;
}
.DigitalBusinessSection2-text-3-left > div,
.DigitalBusinessSection2-text-3-right > div {
  display: flex;
  align-items: flex-start;
  margin-bottom: 16px;
}
.DigitalBusinessSection2-text-3-left > div > p,
.DigitalBusinessSection2-text-3-right > div > p {
  margin-left: 24px;
  margin-bottom: 0px;
  max-width: 383px;
}

.DigitalBusinessSection2-text-3-left > div > p > span,
.DigitalBusinessSection2-text-3-right > div > p > span {
  font-weight: bold;
}
/* ////////////// Tablet Design ////////////// */
@media only screen and (max-width: 768px) {
  .DigitalBusinessSection2 {
    margin-top: 48px;
    padding: 0 24px;
  }
  .DigitalBusinessSection2-text-3 {
    margin-top: 24px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 0;
  }
  .DigitalBusinessSection2-text-3-left > div > p,
  .DigitalBusinessSection2-text-3-right > div > p {
    max-width: 596px;
  }
}

/* ////////////// Mobile Design ////////////// */
@media only screen and (max-width: 426px) {
  .DigitalBusinessSection2 {
    margin-top: 32px;
    padding: 0 16px;
  }
  .DigitalBusinessSection2-text-3-left > div > p,
  .DigitalBusinessSection2-text-3-right > div > p {
    max-width: 303px;
    margin-left: 16px;
  }
}
