.ITManagementSection3 {
  background-color: #e3d3ff33;
  padding: 48px 96px;
}

/* .ITManagementSection3-top {
} */

.ITManagementSection3-top-card {
  text-align: left;
  margin-bottom: 16px;
  max-width: 606px;
  /* padding: 0 !important; */
}

.ITManagementSection3-top-card-title {
  color: #242424;
  font-size: 25px;
  font-weight: 600;
  line-height: 38px;
  letter-spacing: 0em;
}

.ITManagementSection3-top-card-points {
  color: #494949;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  margin-top: 32px;
  max-width: 606px;
}

.ITManagementSection3-top-card-points > ul {
  margin: 0;
}

.ITManagementSection3-top-card-points > ul > li {
  margin-bottom: 16px;
}

.ITManagementSection3-bottom {
  margin-top: 16px;
}

.ITManagementSection3-bottom > a {
  text-decoration: none;
}

.ITManagementSection3-bottom-btn {
  width: 232.47px;
  height: 59px;
  border-radius: 8px;
  background-color: rgba(95, 73, 146, 1);
  color: white;
  font-size: 18px;
  font-weight: 500;
  line-height: 27px;
  letter-spacing: 0em;
  text-align: left;
  display: flex;
  align-items: center;
  justify-content: center;
}
/* ////////////// Tablet Design ////////////// */

@media only screen and (max-width: 1024px) {
  .ITManagementSection3 {
    padding: 32px 24px;
  }
  .responsive-row {
    flex-direction: column;
  }
  .ITManagementSection3-bottom-btn {
    width: 161px;
    height: 48px;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0em;
  }
}

/* ////////////// Modile Design ////////////// */

@media only screen and (max-width: 426px) {
  .ITManagementSection3 {
    padding: 32px 16px;
  }
}
