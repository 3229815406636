.WorkingSteps {
  width: 100%;
  margin-top: 80px;
  padding: 0 138px;
}

.WorkingStepsText-1 {
  font-size: 25px;
  font-weight: 600;
  line-height: 38px;
  letter-spacing: 0em;
  text-align: center;
  color: #5b5b5b;
}
.WorkingStepsText-2 {
  font-size: 40px;
  font-weight: 600;
  line-height: 52px;
  letter-spacing: 0em;
  text-align: center;
  color: linear-gradient(0deg, #242424, #242424);
  margin-top: 16px;
}
.WorkingStepsText-2 > span {
  background: linear-gradient(0deg, #6d54a8, #6d54a8);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.WorkingStepsText-3 {
  color: #5b5b5b;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: center;
  margin: auto;
  margin-top: 16px;
  max-width: 884px;
}
.WorkingStepsGraph {
  margin-top: 48px;
}

/* ////////////// Tablet Design ////////////// */
@media only screen and (max-width: 768px) {
  .WorkingSteps {
    margin-top: 48px;
    padding: 0 24px;
  }

  .WorkingStepsText-1 {
    font-size: 18px;
    line-height: 27px;
  }
  .WorkingStepsText-2 {
    font-size: 25px;
    font-weight: 600;
    line-height: 38px;
    margin-top: 8px;
  }

  .WorkingStepsText-3 {
    margin: auto;
    margin-top: 16px;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    max-width: 585px;
  }
}

/* ////////////// Mobile Design ////////////// */
@media only screen and (max-width: 426px) {
}
